<template>
  <div class="subNavDate pt-4 pb-3">
    <div class="ml-1">
      <router-link to="/main/herbarium">
        {{ $t('profile_category_herbariums') }}
      </router-link>
      <!--      <b-link to="/main/actions" disabled>-->
      <!--      {{ $t('profile_category_actions') }}-->
      <!--    </b-link>-->
      <RoleRouterLink
        :to="'/main/handbooks'"
        :name="$t('profile_herbariums_handbooks')"
        :access="['editor', 'admin']"
      />
      <RoleRouterLink
        :to="'/main/users'"
        :name="$t('profile_category_users')"
        :access="['admin']"
      />
    </div>
    <button
      v-if="showSaveButton"
      class="subNavDateSaveResult"
      @click="saveFiles"
    >
      <span class="subNavDateSaveResultText">
        {{ $t('main_page_sub_nav_save_result') }}
      </span>
    </button>
    <Modal
      :title="$t('modal_download_file_title')"
      :id-modal="'saveFiles'"
      size="md"
    >
      <template #modalBody>
        <ExportFilesForm />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import RoleRouterLink from '@/components/RoleRouterLink'
import { mapGetters } from 'vuex'
import Modal from '@/components/Modal'

import ExportFilesForm from '@/components/ExportFilesForm'

export default {
  components: { RoleRouterLink, ExportFilesForm, Modal },
  data() {
    return {
      showSaveButton: location.search ? true : false
    }
  },
  computed: {
    ...mapGetters(['getRole'])
  },
  watch: {
    $route(to) {
      if (Object.keys(to.query).length) {
        this.showSaveButton = true
      } else {
        this.showSaveButton = false
      }
    }
  },
  methods: {
    ...mapActions('herbarium', ['setShowFilters']),
    async saveFiles() {
      this.$root.$emit('bv::show::modal', 'saveFiles')
    }
  }
}
</script>

<style lang="scss" scoped>
.subNavDateSortMeny {
  display: none;
}
.subNavDate {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #f1f1f1;
  background: #ffffff;
}
a {
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 2rem;
  color: #333333;
  &:hover {
    opacity: 0.7;
    color: #333333;
    text-decoration: none;
  }
}
.router-link-active {
  border-bottom: 2px solid #04ca69;
  padding-bottom: 19px;
  font-weight: 500;
}
.subNavDateSaveResult {
  padding: 0;
  background: none;
  border: none;
  max-width: 280px;
  color: #00b05a;
  position: relative;
  margin-right: 3rem;
}
.subNavDateSaveResult::after {
  content: '';
  width: 30px;
  height: 30px;
  background: url('../assets/mainPage/icons/icon-button-save-result.svg')
    no-repeat;
  position: absolute;
  top: 3px;
  left: -8px;
}
.subNavDateSaveResultText {
  padding-left: 1rem;
}
button:active,
button:focus {
  outline: none;
}
@media (max-width: 1230px) {
  a {
    padding: 0 1rem;
  }
  .subNavDateSaveResult {
    background: none;
    border: none;
    color: #00b05a;
  }
}
@media (max-width: 520px) {
  .subNavDateSaveResult {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #f2fbf6;
    background-color: #00b05a;
    border-radius: 8px;
    height: 48px;
    font-weight: 500;
    width: 100%;
    margin: 0 0;
    z-index: 1010;
  }
  .subNavDateSaveResult::after {
    content: '';
    background: url('../assets/mainPage/icons/icon-button-save-result-light.svg')
      no-repeat;
    top: 11px;
    left: 74px;
  }
}
</style>
