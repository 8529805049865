<template>
  <div>
    <Navbar />
    <div class="container-xl">
      <SubNavDate />
      <div class="mt-5">
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script>
import SubNavDate from '@/components/SubNavDate.vue'
import Navbar from '@/components/Navbar'

export default {
  name: 'HerbariumMainPage',
  components: {
    Navbar,
    SubNavDate
  }
}
</script>

<style lang="scss" scoped>
.container-xl {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
