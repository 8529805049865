<template>
  <router-link v-if="isShow" :to="to">{{ name }}</router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    to: { type: String, default: () => '/' },
    name: { type: String, default: () => 'RoleRouterLink' },
    access: { type: Array, default: () => [] }
  },
  computed: {
    ...mapGetters(['getRole']),
    isShow: function() {
      return this.access.some(el => el === this.getRole)
    }
  }
}
</script>
