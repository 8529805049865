<template>
  <b-row>
    <b-col>
      <b-button variant="danger" block>
        {{ $t('herbarium_form_page_btn_cancel') }}
      </b-button>
    </b-col>
    <b-col>
      <b-button variant="success" block @click="saveCsv">
        <b-icon icon="arrow-down" />
        csv
      </b-button>
    </b-col>
    <b-col>
      <b-button variant="warning" block @click="saveZip">
        <b-icon icon="arrow-down" />
        zip
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import axios from '../axiosApi'
import fileDownload from 'js-file-download'

export default {
  methods: {
    async saveCsv() {
      await axios
        .get('/export/csv' + location.search)
        .then(({ data }) => {
          fileDownload(data, new Date() + '.csv')
        })
        .catch(({ data }) => {
          this.$store.dispatch('showToast', {
            type: 'warning',
            message: data.code
          })
        })
      this.close()
    },
    async saveZip() {
      await axios({
        url: '/export/zip' + location.search,
        method: 'GET',
        responseType: 'blob'
      })
        .then(({ data }) => {
          fileDownload(data, new Date() + '.zip')
        })
        .catch(() => {
          this.$store.dispatch('showToast', {
            type: 'warning',
            message: 'ERR023'
          })
        })
      this.close()
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'saveFiles')
    }
  }
}
</script>

<style></style>
